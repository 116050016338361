import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { usePage } from '@inertiajs/inertia-react';

interface MainLayoutProps {
  children: React.ReactNode;
  isContainer?: boolean;
}

const MainLayout = ({ children, isContainer = true }: MainLayoutProps) => {
  const page = usePage();
  const { flash } = page.props as {
    flash?: {
      success: string | null;
      alert: string | null;
    };
  };

  useEffect(() => {
    if (flash?.success) {
      toast.success(flash.success);
    } else if (flash?.alert) {
      toast.error(flash.alert, { autoClose: 10_000 });
    }
  }, [flash]);

  return (
    <>
      <section className="flex flex-col min-h-screen">
        <Navbar />
        <div className={`${isContainer ? 'container mx-auto ' : ''}flex-grow`}>{children}</div>
        <ToastContainer position="bottom-right" />
      </section>
      <Footer />
    </>
  );
};

export default MainLayout;
