import React, { useState } from 'react';
import { usePage, Link } from '@inertiajs/inertia-react';
import { Popover } from '@headlessui/react';
import clsx from 'clsx';
import { Inertia } from '@inertiajs/inertia';

const Navbar = () => {
  const page = usePage();
  const { auth, has_listings: userHasListings } = page.props as {
    auth?: { user: Partial<schema.User> };
    has_listings?: boolean;
  };
  const currentUser = auth?.user;
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);

  return (
    <>
      <nav className="flex items-center justify-between flex-wrap py-4 lg:px-10 px-3 text-gray-700 bg-white border-b border-gray-200 shadow z-10">
        {/* Nav header */}
        <div className="flex items-center flex-no-shrink mr-6 text-xl">
          <Link href={'/'} className="font-semibold tracking-wide">
            <span className="text-gray-600">Functional</span>{' '}
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-teal-500 to-teal-500/90 ">
              Source
            </span>
          </Link>
        </div>
        {/* Menu button on small screens */}
        <div className="block lg:hidden">
          <button
            onClick={() => setIsNavMenuOpen((prevState) => !prevState)}
            className="flex items-center px-3 py-2 border rounded text-gray-600 border-indigo-800 hover:text-teal-700 hover:border-teal-700"
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        {/* Nav links */}
        <div
          className={clsx('w-full lg:flex-1 lg:flex items-center text-right', {
            block: isNavMenuOpen,
            hidden: !isNavMenuOpen,
          })}
        >
          <div className="flex lg:flex-row flex-col lg:justify-between w-full">
            <div className="flex flex-col sm:flex-row">
              <div className="flex">
                <Link
                  href={'/providers'}
                  style={{ whiteSpace: 'nowrap' }}
                  className="block mt-4 lg:inline-block tracking-wide font-medium lg:mt-0 lg:mr-5 mb-2 lg:mb-0"
                >
                  Browse
                </Link>
              </div>
              <div className="flex">
                {currentUser ? (
                  <Link
                    href={'/list-your-practice'}
                    style={{ whiteSpace: 'nowrap' }}
                    className="block mt-4 lg:inline-block tracking-wide font-medium lg:mt-0 lg:mr-5 mb-2 lg:mb-0 plausible-event-name=List+Practice+Click plausible-event-signedin=true"
                  >
                    List your practice
                  </Link>
                ) : (
                  <div
                    onClick={() => {
                      if (confirm('Please log in or sign up to list your practice.'))
                        Inertia.visit('/signup');
                    }}
                    className="cursor-pointer block mt-4 lg:inline-block tracking-wide font-medium lg:mt-0 lg:mr-5 mb-2 lg:mb-0 plausible-event-name=List+Practice+Click plausible-event-signedin=false"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    List your practice
                  </div>
                )}
              </div>
              {userHasListings ? (
                <div className="flex">
                  <Link
                    href={'/my-listings'}
                    style={{ whiteSpace: 'nowrap' }}
                    className="block mt-4 lg:inline-block tracking-wide font-medium lg:mt-0 lg:mr-5 mb-2 lg:mb-0"
                  >
                    My listings
                  </Link>
                </div>
              ) : null}

              <div className="hidden">
                <Link
                  href={'/blog'}
                  style={{ whiteSpace: 'nowrap' }}
                  className="block mt-4 lg:inline-block tracking-wide font-medium lg:mt-0 lg:mr-5 mb-2 lg:mb-0"
                >
                  Blog
                </Link>
              </div>

              <div className="flex">
                <Link
                  href={'/faq'}
                  style={{ whiteSpace: 'nowrap' }}
                  className="block mt-4 lg:inline-block tracking-wide font-medium lg:mt-0 lg:mr-5 mb-2 lg:mb-0"
                >
                  FAQ
                </Link>
              </div>
            </div>
            {currentUser?.id ? (
              <>
                <div className="flex relative justify-end mt-4 lg:mt-0 text-right lg:text-left text-sm items-center">
                  <Popover>
                    <Popover.Button className="hidden lg:flex">
                      <div>
                        <i className="fa-solid fa-circle-user fa-2xl"></i>
                      </div>
                    </Popover.Button>
                    <Popover.Panel>
                      <div className="absolute -right-5 top-11 w-full border border-gray-100 rounded-md shadow-lg md:w-64">
                        <div className="flex flex-col p-5 bg-white rounded-md shadow">
                          <div className="text-sm mb-5">{currentUser.email}</div>
                          {currentUser?.isAdmin ? (
                            <div className="flex flex-col lg:flex-row text-sm mb-5">
                              <div className="flex">
                                <a
                                  href={'/motor_admin'}
                                  style={{ whiteSpace: 'nowrap' }}
                                  className="block mt-4 lg:inline-block text-blue-800 lg:mt-1 lg:mr-5 mb-2 lg:mb-0"
                                >
                                  Admin
                                </a>
                              </div>
                            </div>
                          ) : null}
                          <div>
                            <a
                              href="/logout"
                              className="py-1.5 px-5 rounded text-sm font-semibold cursor-pointer border border-gray-300 hover:bg-gray-100 w-20"
                            >
                              Log Out
                            </a>
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Popover>
                </div>
                <div className={clsx({ hidden: !isNavMenuOpen })}>
                  <div className="flex flex-col mt-4">
                    <div className="text-sm mb-5">{currentUser.email}</div>
                    <div>
                      <a
                        href="/logout"
                        className="py-1.5 px-5 rounded text-sm font-semibold cursor-pointer border border-gray-300 hover:bg-gray-100 w-20"
                      >
                        Log Out
                      </a>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="w-full block lg:flex lg:flex-row lg:flex-1 mt-2 lg:mt-0 text-right lg:text-left lg:justify-end text-sm items-center">
                <Link
                  href="/signup"
                  className="block mt-4 lg:inline-block cursor-pointer tracking-wide font-medium lg:mt-0 lg:mr-5 mb-2 lg:mb-0"
                >
                  Sign Up
                </Link>
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
